<template>
    <div class="layout-dashboard">
        <div class="grid">
            <div class='col-12 md:col-6 xl:col-12'>
                <div class='card grid-nogutter widget-overview-box widget-overview-box-2'>
                    <span class='overview-icon'>
                        <i class='pi pi-dollar'></i>
                    </span>
                    <span class='overview-title'>Saldo total</span>
                    <div class='grid overview-detail'>
                        <div class='col-6'>
                            <div class='overview-number'>{{ formatCurrency(expensesTotal) }}</div>
                            <div class='overview-subtext'>Total de despesas</div>
                        </div>
                        <div class='col-6'>
                            <div class='overview-number'>{{ formatCurrency(incomesTotal) }}</div>
                            <div class='overview-subtext'>Total de receita</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class='col-12 xl:col-12'>
                <div class='card'>
                    <div class='card-header'>
                        <h4>Saldo das contas de bancos</h4>
                    </div>

                    <DataTable ref='dt' :value='banks' dataKey='id'
                               :paginator='true' :rows='10' :filters='filters'
                               paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                               :rowsPerPageOptions='[5,10,25]'
                               currentPageReportTemplate='Mostrando {first} ~ {last} total {totalRecords} contas de banco'
                               responsiveLayout='scroll'>
                        <template #header>
                            <div class='flex flex-column md:flex-row md:justify-content-between md:align-items-center'>
                                <h5 class='m-0'>Contas de banco</h5>
                                <span class='block mt-2 md:mt-0 p-input-icon-left'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Pesquisar...' />
                            </span>
                            </div>
                        </template>

                        <Column selectionMode='multiple' headerStyle='width: 3rem'></Column>
                        <Column headerStyle='width: 15rem' field='name' header='Nome' :sortable='true'>
                            <template #body='slotProps'>
                                <span class='p-column-title'>Nome</span>
                                {{ slotProps.data.name }}
                            </template>
                        </Column>
                        <Column headerStyle='width: 10rem' field='agency' header='Agência' :sortable='true'>
                            <template #body='slotProps'>
                                <span class='p-column-title'>Agência</span>
                                {{ slotProps.data.agency }}
                            </template>
                        </Column>
                        <Column headerStyle='width: 10rem' field='account' header='Conta' :sortable='true'>
                            <template #body='slotProps'>
                                <span class='p-column-title'>Conta</span>
                                {{ slotProps.data.account }}
                            </template>
                        </Column>
                        <Column headerStyle='width: 20rem' field='balance' header='Saldo Atual' :sortable='true'>
                            <template #body='slotProps'>
                                <span class='p-column-title'>Balanço Atual</span>
                                {{ formatCurrency(slotProps.data.balance) }}
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BankService from '@/services/bank.service';
import { FilterMatchMode } from 'primevue/api';

export default {
    data() {
        return {
            expensesTotal: 0,
            incomesTotal: 0,
            banks: {},
            filters: {},
        };
    },
    created() {
        this.initFilters();
        this.fetchBanks();
    },
    methods: {
        menuToggle($event) {
            this.$refs.menu.toggle($event);
        },

        refreshDataset($event) {
            this.$refs.chart.reinit($event);
        },

        formatCurrency(value) {
            if (value || value === 0) return value.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' });
        },
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        async fetchBanks() {
            let resp = await BankService.find();
            this.banks = resp.data;
        },
    },
};
</script>


<style lang="scss" scoped>
.layout-dashboard {
    .orders {
        h4 {
            margin-bottom: 20px;
        }

        .p-button {
            margin-top: -20px;
        }

        .order-tabs {
            margin-bottom: 1rem;

            .order-tab {
                padding: 1rem 2rem 2rem 1rem;
                position: relative;
                transition: box-shadow 0.2s;

                &:hover {
                    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
                    cursor: pointer;
                }

                i {
                    font-size: 1rem;
                    vertical-align: middle;
                }

                .order-label {
                    margin-left: 0.25rem;
                    vertical-align: middle;
                }

                .stat-detail-icon {
                    position: absolute;
                    right: 1rem;
                    top: 2.25rem;
                    height: 1rem;
                    width: 1rem;
                }

                img {
                    position: absolute;
                    bottom: 0;
                    left: 5%;
                    width: 90%;
                }
            }
        }
    }

    .overview-chart {
        overflow: auto;
    }

    .dashbard-demo-dropdown {
        min-width: 8rem;
        margin-left: auto;
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }
    }
}
</style>
